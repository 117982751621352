import { createReducer, on } from '@ngrx/store';
import { decrement, increment, set } from './noti.actions';

import { INoti } from './noti.states';

const initialState: INoti = {
  navNoti: 0,
  mercListNoti: 0,
  settlementNoti: 0,
  refundNoti: 0,
};

export const notiReducer = createReducer(
  initialState,
  on(set, (state, action) => {
    return { ...state, ...action.noti };
  }),
  on(increment, (state, action) => {
    const key = action.value;
    const tempState = { ...state };
    tempState[key] += 1;

    state = tempState;

    return state;
  }),
  on(decrement, (state, action) => {
    const key = action.value;
    const tempState = { ...state };

    if (tempState[key] > 0) {
      tempState[key] -= 1;
    }

    state = tempState;

    return state;
  })
);
